<template>
  <v-row justify="center">
    <v-col v-if="instanceSettings['name']" cols="12" xl="6" lg="8">
      <v-form
        ref="form"
        v-model="isValid"
        lazy-validation
        @submit.prevent="updateInstanceSettings()"
      >
        <div class="text-overline">
          Allgemein
        </div>
        <v-text-field
          v-model="instanceSettings['name'].value"
          filled
          label="Name"
        />

        <div class="text-overline mt-4">
          Kontakt
        </div>

        <v-text-field
          v-model="instanceSettings['email'].value"
          filled
          label="E-Mail"
        />

        <v-text-field
          v-model="instanceSettings['phone'].value"
          filled
          label="Telefon"
        />

        <div class="text-overline mt-4">
          Adresse
        </div>
        <v-text-field
          v-model="instanceSettings['address1'].value"
          filled
          label="Straße und Hausnummer"
        />
        <v-text-field
          v-model="instanceSettings['address2'].value"
          filled
          label="Adresszusatz"
        />

        <v-row>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="instanceSettings['zip'].value"
              filled
              label="PLZ"
            />
          </v-col>
          <v-col cols="12" md="7">
            <v-text-field
              v-model="instanceSettings['city'].value"
              filled
              label="Ort"
            />
          </v-col>
        </v-row>

        <div class="text-overline mt-4">
          Branding
        </div>
        <v-text-field
          v-model="instanceSettings['primary_color'].value"
          filled
          label="Primärfarbe (möglichst dunkel) / HEX-Code, sowie RGBA möglich."
        >
          <template v-slot:append>
            <v-avatar
              :color="instanceSettings['primary_color'].value"
              size="30px"
            />
          </template>
        </v-text-field>

        <v-text-field
          v-model="instanceSettings['secondary_color'].value"
          filled
          label="Sekundärfarbe"
        >
          <template v-slot:append>
            <v-avatar
              :color="instanceSettings['secondary_color'].value"
              size="30px"
            />
          </template>
        </v-text-field>

        <div class="text-overline mt-4">
          Logo
        </div>

        <img
          v-if="instanceSettings['brand_image'].value"
          style="max-height: 100px; max-width: 300px;"
          :src="brand_url"
          class="mt-2 mb-2"
        />
        <util-file-drop :file-prop="file" @pass="file = $event" />

        <div class="d-flex justify-end mt-6">
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    instanceSettings: [],
    brand_url: "",
    file: ""
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    this.indexInstanceSettings();
  },
  methods: {
    storeLogo() {
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        this.axios
          .post("/instance-settings/logo", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            window.location.reload(true);
          })
          .catch(e => {
            console.log(e);
            this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
          });
      }
    },
    indexInstanceSettings() {
      this.axios
        .get("/instance-settings")
        .then(r => {
          this.instanceSettings = r.data.instanceSettings;
          this.brand_url = r.data.brand_url;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abruf der Systemeinstellungen ist ein Fehler aufgetreten."
          );
        });
    },
    updateInstanceSettings() {
      this.axios
        .patch("/instance-settings", {
          instanceSettings: this.instanceSettings
        })
        .then(() => {
          if (this.file) {
            this.storeLogo();
          } else {
            window.location.reload(true);
          }
        });
    }
  }
};
</script>

<style scoped></style>
