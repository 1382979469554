<template>
  <v-row justify="center">
    <v-col cols="12" xl="7" lg="9">
      <ui-dialog-card
        :title="isModel ? 'Dokument bearbeiten' : 'Dokument hinzufügen'"
      >
        <template v-slot:text>
          <v-form v-model="isValid" @submit.prevent="submit">
            <div class="text-overline">
              Allgemein
            </div>
            <v-text-field
              v-model="document.name"
              label="Titel*"
              filled
              :counter="280"
              :rules="[rules.required, rules.short_text]"
            />

            <v-textarea
              v-model="document.description"
              label="Beschreibung*"
              filled
              :rules="[rules.required]"
            />
            <div class="mb-2">
              <v-chip
                v-for="(extension, index) in document.filename_extensions"
                :key="extension"
                class="mr-2"
                dark
                color="primary"
                close
                @click:close="removeExtension(index)"
              >
                {{ extension }}
              </v-chip>
            </div>

            <v-text-field
              v-model="extension"
              filled
              label="Erweiterung hinzufügen via ENTER (Format z. B. '.pdf')"
              @keyup.enter="addExtension"
            />
          </v-form>
        </template>

        <template v-slot:actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!isValid"
            :loading="loading"
            @click="submit"
          >
            {{ isModel ? "Speichern" : "Hinzufügen" }}
            <v-icon v-if="!isModel" right dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </ui-dialog-card>
      <v-form> </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    isValid: false,
    loading: false,
    file: null,
    extension: ""
  }),
  computed: {
    document: {
      set(val) {
        this.$store.commit("document/setDocument", val);
      },
      get() {
        return this.$store.getters["document/document"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    isModel() {
      return !!this.$route.params.id;
    }
  },
  watch: {},
  created() {
    if (this.$route.params.id) {
      this.showDocument({ id: this.$route.params.id });
    } else {
      this.document = {
        name: "",
        description: "",
        filename_extensions: []
      };
    }
  },
  methods: {
    ...mapActions({
      showDocument: "document/show",
      storeDocument: "document/store",
      updateDocument: "document/update"
    }),

    // functional
    submit() {
      this.loading = true;
      if (this.isModel) {
        this.update();
      } else {
        this.store();
      }
    },
    update() {
      this.updateDocument({ id: this.$route.params.id, payload: this.document })
        .then(() => {
          this.$snotify.success("Das Dokument wurde erfolgreich aktualisiert.");

          this.$router.push({ name: "SettingsDocuments" });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Aktualisieren des Dokuments ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    store() {
      this.storeDocument({ payload: this.document })
        .then(() => {
          this.$snotify.success("Das Dokument wurde erfolgreich hinzugefügt.");
          this.$router.push({ name: "SettingsDocuments" });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Hinzufügen des Dokuments ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addExtension() {
      this.document.filename_extensions.push(this.extension);
      this.extension = "";
      // this.document.filename_extensions.sort();
    },
    removeExtension(index) {
      this.document.filename_extensions.splice(index, 1);
    }
  }
};
</script>

<style scoped></style>
