<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <v-form
        ref="form"
        v-model="isValid"
        lazy-validation
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein
        </div>

        <v-text-field
          v-model="team.label"
          filled
          label="Kennung"
          :rules="[rules.short_text]"
          required
          :counter="280"
        />

        <v-text-field
          v-model="team.name"
          filled
          label="Name"
          :rules="[rules.required, rules.short_text]"
          :counter="280"
        />

        <v-textarea
          v-model="team.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
        />

        <div class="text-overline mt-4">
          Mitglieder
        </div>

        <v-select
          v-model="team.users"
          filled
          multiple
          :items="users_intern"
          item-value="id"
          item-text="name"
          label="Mitglieder"
        />

        <v-select
          v-model="team.leaders"
          filled
          multiple
          :items="users_intern"
          item-value="id"
          item-text="name"
          label="Teamleiter"
        />

        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'SettingsTeams' }" exact>Abbrechen</v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    team: {
      label: "",
      name: "",
      description: ""
    },
    users_intern: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "SettingsTeamsEdit" && to.params.id) {
        this.showTeam();
        this.indexUsers();
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.showTeam();
    }
    this.indexUsers();
  },
  methods: {
    submit() {
      const url = "/teams";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch(`${url}/${this.$route.params.id}`, {
              team: this.team
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              team: this.team
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showTeam() {
      this.axios
        .get(`/teams/${this.$route.params.id}`)
        .then(r => {
          this.team = { ...r.data.team };
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Teams ist ein Fehler aufgetreten."
          );
        });
    },
    successMethod() {
      this.$router.push({
        name: "SettingsTeams"
      });
      this.$snotify.success("Team wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Teams ist ein Fehler aufgetreten."
      );
    },

    indexUsers() {
      this.axios
        .get("/users")
        .then(res => {
          this.users_intern = res.data.users_intern;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
