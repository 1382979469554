<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="7" lg="9">
        <models-form-edit-view />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
