<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="8">
      <util-user-form type="settings" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    titleItems() {
      return this.$store.getters["application/userTitles"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "SettingsDocumentTypesEdit" && to.params.id) {
        this.showUser();
        this.indexCompanies();
        this.indexCustomerTypes();
        this.indexTeams();
      }
    }
  }
};
</script>

<style scoped></style>
