<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="8">
      <v-form ref="form" v-model="isValid">
        <div id="form-form-header">
          <div class="text-overline mb-2">
            Allgemein
          </div>
          <v-text-field
            v-model="form.name"
            filled
            label="Titel *"
            hide-details="auto"
            :rules="[rules.required, rules.short_text]"
            counter="280"
            @input="updateValue"
          />
          <v-textarea
            v-model="form.body"
            filled
            label="Beschreibung *"
            hide-details="auto"
            :rules="[rules.required, rules.long_text]"
            counter="6000"
            @input="updateValue"
          />
        </div>

        <!-- toolbar -->
        <div class="mx-n4">
          <v-fade-transition>
            <v-toolbar dense flat color="transparent" class="rounded-xl">
              <v-fade-transition>
                <models-label-item v-if="form.is_draft" :label="draftLabel" />
                <models-label-item v-else :label="noDraftLabel" dark />
              </v-fade-transition>
              <v-divider vertical class="mx-3" />
              <div v-if="!saving" class="text-caption">
                <div>Zuletzt gespeichert:</div>
                <div>
                  <span v-if="form.updated_at">
                    {{ form.updated_at | moment("calendar") }}
                  </span>
                  <span v-else>Nie</span>
                </div>
              </div>
              <v-progress-circular v-else indeterminate size="20" width="2" />
              <v-spacer />
              <v-fade-transition>
                <v-btn
                  v-if="form.is_draft"
                  color="primary"
                  rounded
                  small
                  dark
                  @click="updateDraft({ is_draft: false })"
                >
                  Veröffentlichen
                  <v-icon right>
                    mdi-share-circle
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  rounded
                  small
                  dark
                  @click="updateDraft({ is_draft: true })"
                >
                  Veröffentlichung zurückziehen
                  <v-icon right>
                    mdi-undo
                  </v-icon>
                </v-btn>
              </v-fade-transition>
            </v-toolbar>
          </v-fade-transition>
        </div>

        <!-- items -->
        <v-fade-transition>
          <div v-if="form.id" class="mt-16">
            <div class="text-overline mb-2">
              Elemente
            </div>
            <div v-if="form.formSectionItems.length < 1">
              Bisher wurden dem Formular keine Elemente hinzugefügt.
            </div>
            <template v-else>
              <Draggable
                v-model="form.formSectionItems"
                handle=".draggable"
                group="sections"
                @end="updateOrder"
              >
                <template v-for="(formItem, index) in form.formSectionItems">
                  <div :key="`${form.id}-item-${index}`" class="mb-3">
                    <models-form-item-item :form-item="formItem" />
                  </div>
                </template>
              </Draggable>
            </template>
            <div class="mt-2">
              <models-form-item-parent-dialog />
            </div>
          </div>
        </v-fade-transition>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  props: {},
  data: () => ({
    // functional
    saving: false,
    draftLabel: {
      name: "Entwurf",
      description: "Ein Entwurf ist durch Mandanten nicht auswählbar.",
      icon: "mdi-file-outline",
      color: "grey lighten-2"
    },
    noDraftLabel: {
      name: "Veröffentlicht",
      description:
        "Ein veröffentlichtes Formular ist durch Mandanten auswählbar.",
      icon: "mdi-check-circle",
      color: "success"
    },
    isValid: false,
    loading: false
  }),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    this.showForm({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      showForm: "form/show",
      updateForm: "form/update",
      updateOrderForm: "form/updateOrder"
    }),
    updateOrder() {
      this.updateOrderForm({
        id: this.form.id,
        payload: { formSectionItems: this.form.formSectionItems }
      });
    },
    updateValue: _.debounce(function() {
      this.saving = true;

      this.updateForm({
        id: this.form.id,
        payload: { form: this.form }
      }).finally(() => {
        this.saving = false;
      });
    }, 500),
    updateDraft({ is_draft }) {
      this.form.is_draft = is_draft;
      this.updateForm({
        id: this.form.id,
        payload: { form: { is_draft: is_draft } }
      }).finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>

<style scoped></style>
